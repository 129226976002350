import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FaSquareXTwitter,
  FaMedium,
  FaLinkedin,
  FaSquareInstagram,
  FaYoutube,
} from "react-icons/fa6";
import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  Link,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      w={10}
      h={10}
      as={"a"}
      href={href}
      color="Neutral.700"
      bg="Neutral.100"
      _hover={{
        color: "Neutral.950",
        bg: "Neutral.300",
      }}
      cursor={"pointer"}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      borderRadius={"full"}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("footer");

  return (
    <Box
      align={"center"}
      bg="Neutral.900"
      borderBottom={1}
      borderStyle={"solid"}
      borderColor="Neutral.700"
      boxShadow={"lg"}
      zIndex="popover"
      position="relative"
      transform="rotateX(180deg)"
    >
      <Container
        as={Stack}
        maxW={"8xl"}
        py={4}
        px={14}
        direction={{ base: "column", md: "row" }}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
        transform="rotateX(180deg)"
      >
        {/* Social Media */}
        <Stack direction={"row"} spacing={4}>
          <SocialButton
            label={"XTwitter"}
            href={"https://twitter.com/MeritoNetwork"}
          >
            <FaSquareXTwitter />
          </SocialButton>
          <SocialButton
            label={"Medium"}
            href={"https://medium.com/@merito.network"}
          >
            <FaMedium />
          </SocialButton>
          <SocialButton
            label={"LinkedIn"}
            href={"https://www.linkedin.com/company/meritonetwork"}
          >
            <FaLinkedin />
          </SocialButton>
          {/* <SocialButton label={"Instagram"} href={"#"} >
            <FaSquareInstagram />
          </SocialButton>
          <SocialButton label={"YouTube"} href={"#"} >
            <FaYoutube />
          </SocialButton> */}
        </Stack>

        {/* Copyright  */}
        <Text color="Neutral.100">© 2024 Merito. All rights reserved.</Text>

        {/* About Us */}
        <Link
          className={i18n.language === "jp" ? "jp" : "en"}
          color="Neutral.100"
          _hover={{
            textDecoration: "underline",
          }}
          cursor={"pointer"}
          onClick={() => {
            navigate("/about-us");
          }}
        >
          {t("about_us")}
        </Link>
      </Container>
    </Box>
  );
}
