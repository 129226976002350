import axios from "axios";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT = "/contact/project";

export default function ProjectContactForm({ project_name }) {
  const { t, i18n } = useTranslation("contact");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [inquiryID, setInquiryID] = useState(null);
  // const [message, setMessage] = useState(null);  // TODO
  // const [errorMessage, setErrorMessage] = useState(null);  // TODO

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);

    try {
      console.log(`Calling Merito API: ${ENDPOINT}`);
      const response = await axios.post(`${MERITO_API_HOST}${ENDPOINT}`, {
        project_name: project_name,
        name: name,
        email: email,
        message: message,
      });

      console.log("Inquiry confirmation email sent:\n", response.data.content);
      setInquiryID(response.data.content.id);
      // setMessage(msg);  // TODO
    } catch (err) {
      const errMsg = "Failed to send the contact form:\n";
      console.error(errMsg, err);
      // setErrorMessage(errMsg);  // TODO
    }
  };

  useEffect(() => {
    setDisabled(name && email && message ? false : true);
  }, [name, email, message]);

  return (
    <Flex>
      <Stack className={i18n.language === "jp" ? "jp" : "en"}>
        <Box borderRadius={"lg"} bg="Neutral.0" p={10}>
          {/* Title */}
          <Stack mt={-1} mb={9}>
            <Text
              color="Secondary.500"
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "2xl", md: "3xl", lg: "3xl" }}
              textAlign={"center"}
            >
              {t("title")}
            </Text>

            {inquiryID && (
              <Text
                fontSize={"lg"}
                textAlign={"left"}
                color="Secondary.500"
                pt={4}
                px={6}
              >
                {t("description_top_1")}
                <br />
                {t("description_top_2")}
                <Text as="span" fontWeight={600}>
                  {inquiryID}
                </Text>
              </Text>
            )}
          </Stack>

          {/* Form */}
          <Stack spacing={4}>
            <FormControl isReadOnly>
              <FormLabel>{t("project_name")}</FormLabel>
              <Input
                id="project_name"
                value={project_name}
                type="text"
                color="ScienceDeals.600"
                fontWeight={600}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t("name")}</FormLabel>
              <Input
                onChange={(e) => setName(e.target.value)}
                id="name"
                value={name}
                type="text"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t("email")}</FormLabel>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                value={email}
                type="email"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t("message")}</FormLabel>
              <Textarea
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                value={message}
                type="text"
                placeholder=""
              />
            </FormControl>

            <Stack pt={4} px={1}>
              <Text align={"left"} color="Neutral.800" fontSize="14">
                {t("description_bottom_1")}
                <Text as="span" color="Secondary.700">
                  {t("description_bottom_2")}
                </Text>
                {t("description_bottom_3")}
              </Text>
            </Stack>
            <Stack py={3}>
              <Button
                className={i18n.language === "jp" ? "jp" : "en"}
                isDisabled={disabled}
                variant="OrangePrimary"
                onClick={handleSubmit}
              >
                {t("send_button")}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
