import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SimpleGrid, Box, Image, Text, Stack, Divider } from "@chakra-ui/react";

import { useLoading } from "../context/LoadingProvider";
import { useUserAuth } from "../context/UserAuthProvider";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT = "/science_deals/projects";

export default function ScienceDealsListing() {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { firebaseBuildAuthHeader } = useUserAuth();

  const [projects, setProjects] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  // const [message, setMessage] = useState(null);  // TODO
  // const [errorMessage, setErrorMessage] = useState(null);  // TODO

  useEffect(() => {
    console.log("Projects:", projects);
  }, [projects]);

  useEffect(() => {
    const getProjects = async () => {
      setIsLoading(true);
      setIsLoadingProjects(true);

      try {
        console.log(`Calling Merito API: ${ENDPOINT}`);
        const authHeader = await firebaseBuildAuthHeader();
        const response = await axios.get(
          `${MERITO_API_HOST}${ENDPOINT}`,
          authHeader
        );

        setProjects(response.data.content);
        console.log("Projects retrieved");
        // setMessage(msg);  // TODO
      } catch (err) {
        const errMsg = "Failed to retrieve projects:\n";
        console.error(errMsg, err);
        // setErrorMessage(errMsg);  // TODO
      } finally {
        // Data fetching is complete
        setIsLoadingProjects(false);
        setIsLoading(false);
      }
    };

    getProjects();
  }, []);

  // Ensure projects are loaded before rendering
  if (isLoadingProjects || !projects) {
    return (
      <Text color="ScienceDeals.500" fontSize="2xl" mt={"30vh"}>
        Loading Science Deals Projects...
      </Text>
    );
  }

  return (
    <>
      {/* Title */}
      <Stack w="full" textAlign="center" my={{ base: 10, md: 14 }}>
        <Text
          whiteSpace="normal"
          overflowWrap="break-word"
          textAlign="center"
          color="ScienceDeals.200"
          fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
          px={{ base: 4, md: 0 }}
          mb={8}
        >
          Science Deals Projects
        </Text>
        <Divider
          orientation="horizontal"
          borderColor="Neutral.600"
          borderWidth="1px"
        />
      </Stack>

      {/* Project Grid */}
      <SimpleGrid columns={{ sm: 2, md: 3, lg: 4 }} spacing={5} p={8} mt={-8}>
        {projects.map((project) => (
          <Box
            key={project.project_id}
            maxW="sm"
            overflow="hidden"
            _hover={{ cursor: "pointer" }}
            borderWidth="1px"
            rounded="xl"
            boxShadow="xl"
            onClick={() => {
              navigate(`/science-deals/project/${project.project_id}`, {
                state: { project },
              });
            }}
          >
            <Image
              src={project.project_image_url}
              alt={`Project titled: ${project.project_name}`}
            />
            <Box p="6">
              <Text
                isTruncated
                as="h4"
                color="ScienceDeals.300"
                fontWeight={600}
                lineHeight="tight"
                mt="0"
              >
                {project.project_name}
              </Text>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </>
  );
}
