import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Container,
  Text,
  Button,
  Stack,
  VStack,
  Image,
  Icon,
  useColorModeValue,
  createIcon,
  Avatar,
  Divider,
  Center,
} from "@chakra-ui/react";

import { useUserAuth } from "../context/UserAuthProvider";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT = "/user/user_id/resources";

export default function Portal() {
  const navigate = useNavigate();
  const { currentMeritoUser, meritoSignedIn, firebaseBuildAuthHeader } =
    useUserAuth();

  const [resources, setResources] = useState(null);
  const [isLoadingResources, setIsLoadingResources] = useState(true);
  // const [message, setMessage] = useState(null);  // TODO
  // const [errorMessage, setErrorMessage] = useState(null);  // TODO

  const checkSignInStatus = () => {
    // Move to the sign-in page if the user is not signed in
    if (!JSON.parse(localStorage.getItem("meritoSignedIn"))) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkSignInStatus();

    if (currentMeritoUser) {
      const getResources = async () => {
        try {
          console.log(`Calling Merito API: ${ENDPOINT}`);
          const authHeader = await firebaseBuildAuthHeader();
          const response = await axios.get(
            `${MERITO_API_HOST}${ENDPOINT.replace(
              "user_id",
              currentMeritoUser.user_id
            )}`,
            authHeader
          );

          const content = response.data.content;
          if (
            content.seller.projects.length > 0 ||
            content.seller.sras.length > 0 ||
            content.buyer.projects.length > 0 ||
            content.buyer.sras.length > 0
          ) {
            setResources(response.data.content);
          } else {
            setResources(null);
          }

          setIsLoadingResources(false);
          console.log("Resources retrieved");
          // setMessage(msg);  // TODO
        } catch (err) {
          const errMsg = "Failed to retrieve Resources:\n";
          console.error(errMsg, err);
          // setErrorMessage(errMsg);  // TODO
        }
      };

      getResources();
    }
  }, [meritoSignedIn]);

  return (
    <Container maxW={{ base: "100%", xl: "70%" }} py={12}>
      {/* Title */}
      <Text
        textAlign={"center"}
        color="Neutral.100"
        fontSize={{ base: "2xl", md: "3xl" }}
        mt={{ base: 4, md: 6 }}
        mb={{ base: 12, md: 16 }}
      >
        Hello,{" "}
        <Text as="span" color="Secondary.400">
          {currentMeritoUser?.username}
        </Text>
      </Text>

      {/* Items */}
      <Flex
        direction={{ base: "column", xl: "row" }}
        justifyContent={"center"}
        alignItems="center"
      >
        <Container w={{ base: "full", xl: "50%" }}>
          {/* Profile Page */}
          <Stack
            w="full"
            flex={{ base: 1, md: 0 }}
            justify="center"
            align="center"
            direction="column"
            bg="Neutral.300"
            color="Neutral.0"
            borderRadius="xl"
            boxShadow="2xl"
            _hover={{
              bg: "Neutral.400",
              boxShadow: "xl",
            }}
            cursor="pointer"
            py={{ base: 8, md: 16 }}
            onClick={() => navigate(`/user/${currentMeritoUser.user_id}`)}
          >
            <Avatar
              name={currentMeritoUser?.username}
              src={currentMeritoUser?.user_image_url || ""}
              bgGradient="linear(to-b, ScienceDeals.600, PatentDeals.600)"
              color="Neutral.50"
              size="xl"
              mt={2}
              mb={4}
            />
            <Text fontSize={{ base: "xl", md: "2xl" }}>Profile Settings</Text>
          </Stack>

          {/* Science Deals and Patent Deals */}
          <Stack
            w="full"
            flex={{ base: 1, md: 0 }}
            direction={{ base: "column", md: "row" }}
            align="center"
            spacing={7}
            mt={7}
          >
            <Button
              w={{ base: "full", md: "50%" }}
              bg="ScienceDeals.500"
              color="Neutral.0"
              fontSize={{ base: "xl", md: "2xl" }}
              borderRadius="xl"
              boxShadow="2xl"
              _hover={{
                bg: "ScienceDeals.600",
                boxShadow: "xl",
              }}
              cursor="pointer"
              py={{ base: 12, md: 16 }}
              onClick={() => navigate("/science-deals/dashboard")}
            >
              <Text as="span" whiteSpace="normal" overflowWrap="break-word">
                Science Deals
              </Text>
            </Button>
            <Button
              w={{ base: "full", md: "50%" }}
              bg="PatentDeals.500"
              color="Neutral.0"
              fontSize={{ base: "xl", md: "2xl" }}
              borderRadius="xl"
              boxShadow="2xl"
              _hover={{
                bg: "PatentDeals.600",
                boxShadow: "xl",
              }}
              cursor="pointer"
              py={{ base: 12, md: 16 }}
              onClick={() => navigate("/patent-deals/dashboard")}
            >
              <Text as="span" whiteSpace="normal" overflowWrap="break-word">
                Patent Deals
              </Text>
            </Button>
          </Stack>
        </Container>

        {/* Resources */}
        <Container w={{ base: "full", xl: "50%" }} mt={{ base: 7, xl: 0 }}>
          <Box
            bg="Neutral.100"
            border="1px"
            borderColor="Neutral.200"
            borderRadius="xl"
            boxShadow="2xl"
            _hover={{
              boxShadow: "xl",
            }}
            p={8}
            pt={9}
          >
            <Text
              color="Neutral.700"
              textAlign="center"
              fontSize={{ base: "xl", md: "2xl" }}
              mb={6}
            >
              {/* Resources */}
              Projects
            </Text>
            <VStack
              h="318px"
              overflowY="auto"
              spacing={4}
              align="start"
              border="1px"
              borderColor="Neutral.300"
              borderRadius="md"
              p={1}
            >
              {resources ? (
                resources.seller.projects.map((project, index) => (
                  <Box
                    key={index}
                    role="group"
                    w="full"
                    bg="Neutral.50"
                    color="Neutral.800"
                    border="1px"
                    borderColor="Neutral.300"
                    borderRadius="md"
                    boxShadow="xl"
                    _hover={{
                      bg: "Neutral.100",
                      boxShadow: "lg",
                      cursor: "pointer",
                    }}
                    p={4}
                    onClick={() =>
                      navigate(`/science-deals/project/${project.project_id}`, {
                        state: { project },
                      })
                    }
                  >
                    <Text
                      color="Neutral.600"
                      fontSize={{ base: "sm", md: "md" }}
                      _groupHover={{
                        color: "Primary.700",
                      }}
                    >
                      {project.project_name}
                    </Text>
                    <Text
                      color="Neutral.400"
                      fontSize={{ base: "xs", md: "sm" }}
                      mt={1}
                    >
                      {project.project_goal}
                    </Text>
                  </Box>
                ))
              ) : (
                <Flex w="full" h="full" justify="center" align="center" p={4}>
                  <Text
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    fontSize={{ base: "md", md: "lg" }}
                    color="Neutral.500"
                  >
                    {isLoadingResources
                      ? "Loading Projects..."
                      : "You haven't listed any projects yet"}
                  </Text>
                </Flex>
              )}
            </VStack>
          </Box>
        </Container>
      </Flex>
    </Container>
  );
}
