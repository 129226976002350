import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Image,
  Text,
  Flex,
  Divider,
  VStack,
  Hide,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import { useUserAuth } from "../context/UserAuthProvider";
import { PDFViewer } from "./PDFViewer";
import { DateFormatter } from "./DateFormatter";
import ProjectContactForm from "./ProjectContactForm";
import { formatNumber } from "../utilities/utilities";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT = "/science_deals/projects";

const projectDurationList = {
  1: "~ 6 months",
  2: "6 months ~ 1 year",
  3: "1 year ~ 3 years",
  4: "3 years ~ 5 years",
  5: "5 years ~ 10 years",
  6: "10 years ~",
};

export default function ScienceDealsProject() {
  const { project_id } = useParams();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { meritoSignedIn, firebaseBuildAuthHeader } = useUserAuth();

  const [project, setProject] = useState(null);
  const [isLoadingProject, setIsLoadingProject] = useState(true);
  const [hideDetails, setHideDetails] = useState(false);
  // const [message, setMessage] = useState(null);  // TODO
  // const [errorMessage, setErrorMessage] = useState(null);  // TODO

  // const handleDealCreation = () => {
  //   // First open a popup modal to confirm the deal creation
  //   // If confirmed:
  //   // - Create a new deal for the project, buyer, and seller (API call)
  //   // - Once deal is created and the deal data is returned,
  //   // - Navigate to the deal page passing the deal data
  //   // - Use "navigate(`/deal/${deal.deal_id}`, { state: { deal } })",
  //        as users could directly access the deal page only with deal_id like the project page
  // };

  // Run when project_id or location.state changes
  useEffect(() => {
    setIsLoadingProject(true);

    // Check if project data was passed via listing page
    if (location.state && location.state.project) {
      // Use the passed project data if available
      setProject(location.state.project);
      setIsLoadingProject(false);
    } else {
      // If no project data was passed, fetch it from the API
      const getProjectData = async () => {
        try {
          console.log(`Calling Merito API: ${ENDPOINT}`);
          const authHeader = await firebaseBuildAuthHeader();
          const response = await axios.get(
            `${MERITO_API_HOST}${ENDPOINT}?project_id=${project_id}`,
            authHeader
          );

          if ("content" in response.data) {
            setProject(response.data.content[0]);
            console.log("Project retrieved:\n", response.data.content[0]);
            // setMessage(msg);  // TODO
          } else {
            const errMsg = `Project not found for project_id: '${project_id}'`;
            console.error(errMsg);
            // setErrorMessage(errMsg);  // TODO
          }
        } catch (err) {
          const errMsg = "Failed to retrieve project data:\n";
          console.error(errMsg, err);
          // setErrorMessage(errMsg);  // TODO
        } finally {
          // Data fetching is complete
          setIsLoadingProject(false);
        }
      };

      getProjectData();
    }
  }, [project_id, location.state]);

  useEffect(() => {
    // Show details if user is signed in
    setHideDetails(!meritoSignedIn);
  }, [meritoSignedIn]);

  // Ensure project is loaded before rendering
  if (isLoadingProject || !project) {
    return (
      <Text color="ScienceDeals.500" fontSize="2xl" mt={"30vh"}>
        Loading Project Details...
      </Text>
    );
  }

  return (
    <Flex direction={{ base: "column", md: "row" }} p={5}>
      <VStack align="flex-start" spacing={12} flex={1} ml={4}>
        <Text
          color="ScienceDeals.100"
          fontWeight={600}
          fontSize="4xl"
          pt={10}
          pl={{ base: 2, md: 6 }}
        >
          {project.project_name}
        </Text>
        <Divider orientation="horizontal" />

        <VStack
          as="span"
          color="ScienceDeals.300"
          fontWeight={500}
          fontSize="2xl"
          spacing={4}
          pl={{ base: 0, md: 8 }}
          align="start"
        >
          <Box
            hidden={hideDetails}
            w="full"
            role="group"
            _hover={{ bg: "Neutral.900" }}
            borderRadius="md"
            p={3}
          >
            <Text>
              Project Owner:{" "}
              <Text color="ScienceDeals.200">{project.project_owner}</Text>
            </Text>
          </Box>

          <Box
            hidden={hideDetails}
            w="full"
            role="group"
            _hover={{ bg: "Neutral.900" }}
            borderRadius="md"
            p={3}
          >
            <Text>
              Organization:{" "}
              <Text color="ScienceDeals.200">{project.organization}</Text>
            </Text>
          </Box>

          <Box
            hidden={hideDetails}
            w="full"
            role="group"
            _hover={{ bg: "Neutral.900" }}
            borderRadius="md"
            p={3}
          >
            <Text>
              Jurisdiction:{" "}
              <Text color="ScienceDeals.200">{project.jurisdiction}</Text>
            </Text>
          </Box>

          <Box
            w="full"
            role="group"
            _hover={{ bg: "Neutral.900" }}
            borderRadius="md"
            p={3}
          >
            <Text>
              Project Description:{" "}
              <Text color="ScienceDeals.200">
                {project.project_description}
              </Text>
            </Text>
          </Box>

          <Box
            hidden={hideDetails}
            w="full"
            role="group"
            _hover={{ bg: "Neutral.900" }}
            borderRadius="md"
            p={3}
          >
            <Text>
              Project Goal:{" "}
              <Text color="ScienceDeals.200">{project.project_goal}</Text>
            </Text>
          </Box>

          <Box
            hidden={hideDetails}
            w="full"
            role="group"
            _hover={{ bg: "Neutral.900" }}
            borderRadius="md"
            p={3}
          >
            <Text>
              Project Start Date:{" "}
              <Text color="ScienceDeals.200">{project.project_start_date}</Text>
            </Text>
          </Box>

          <Box
            w="full"
            role="group"
            _hover={{ bg: "Neutral.900" }}
            borderRadius="md"
            p={3}
          >
            <Text>
              Project Duration:{" "}
              <Text color="ScienceDeals.200">
                {projectDurationList[project.project_duration]}
              </Text>
            </Text>
          </Box>

          <Box
            hidden={hideDetails}
            w="full"
            role="group"
            _hover={{ bg: "Neutral.900" }}
            borderRadius="md"
            p={3}
          >
            <Text>
              Suggested Price:{" "}
              <Text color="ScienceDeals.200">
                {formatNumber(project.suggested_price)}{" "}
                {project.suggested_price_currency}
              </Text>
            </Text>
          </Box>
        </VStack>

        <Box w="full" borderRadius="md" p={3}>
          <PDFViewer pdfUrl={project.project_document_url} />
        </Box>

        {/* Start Deal and Conversation */}
        {/* * The button should be disabled if the user is not signed in, or if the user is the project owner */}
        {/* <Button 
          isDisabled={true}
          variant="ScienceDeals"
          fontSize="2xl"
          p={7}
          onClick={handleDealCreation}
          ml={8}
        >
          Start Deal
        </Button> */}

        {/* Modal for contact form */}
        <Box borderRadius="md" p={3}>
          <Button
            variant="ScienceDeals"
            fontSize="xl"
            p={6}
            mt={-4}
            ml={8}
            mb={{ base: 0, md: 8 }}
            onClick={onOpen}
          >
            Express Interest
          </Button>
          <Modal isCentered isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay bg="blackAlpha.100" backdropFilter="blur(9px)" />
            <ModalContent boxShadow="2xl">
              <ModalCloseButton />
              <ModalBody>
                <ProjectContactForm project_name={project.project_name} />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </VStack>

      <Box flex={1} boxShadow={"xl"}>
        <Image
          src={project.project_image_url}
          alt="Project Image"
          borderRadius={8}
        />
      </Box>
    </Flex>
  );
}
