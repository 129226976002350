import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  getIdToken,
  onAuthStateChanged,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { match } from "path-to-regexp";

import { firebaseConfig } from "../firebase_authentication/firebaseConfig";
import { useLoading } from "./LoadingProvider";

export const UserAuthContext = createContext();
export const firebaseApp = initializeApp(firebaseConfig);

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT_SIGN_UP = "/user/sign_up";
const ENDPOINT_SIGN_IN = "/user/sign_in";

export const UserAuthProvider = ({ children }) => {
  // // List of paths that require user auth check
  // const PATHS_FOR_USER_AUTH_CHECK = [
  //   "/sign-in",
  //   "/sign-up",
  //   "/portal",
  //   "/user/:user_id",
  //   "/deal/:deal_id",
  //   "/science-deals/dashboard",
  //   "/science-deals/project-creation",
  //   "/science-deals/sra-generation",
  //   "/science-deals/listing",
  //   "/science-deals/project/:project_id",
  //   "/patent-deals/dashboard",
  //   "/patent-deals/patent-creation",
  //   "/patent-deals/listing",
  //   "/patent-deals/patent/:patent_id",
  // ];

  const location = useLocation();
  const { setIsLoading } = useLoading();

  const [currentMeritoUser, setCurrentMeritoUser] = useState(null);
  const [meritoSignedIn, setMeritoSignedIn] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  // const [message, setMessage] = useState(null);  // TODO
  // const [errorMessage, setErrorMessage] = useState(null);  // TODO

  // const requiresUserAuthCheck = (currentPath) => {
  //   return PATHS_FOR_USER_AUTH_CHECK.some((path) => {
  //     const matcher = match(path, { decode: decodeURIComponent });
  //     return matcher(currentPath);
  //   });
  // };

  const firebaseUserSignUp = async (email, password) => {
    setIsLoading(true);
    setIsProcessing(true);
    const auth = getAuth();

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;

      // Send verification email to the user
      await sendEmailVerification(firebaseUser);
      console.log("Firebase user signed up and verification email sent");
      // setMessage(msg);  // TODO

      return firebaseUser;
    } catch (err) {
      const errMsg =
        "Failed to sign up Firebase user or send verification email:\n";
      console.error(errMsg, err);
      // setErrorMessage(errMsg);  // TODO
    } finally {
      setIsLoading(false);
    }
  };

  const firebaseUserSignIn = async (email, password) => {
    setIsLoading(true);
    setIsProcessing(true);
    const auth = getAuth();

    try {
      // Sign in with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;

      return firebaseUser;
    } catch (err) {
      const errMsg = "Failed to sign in to Firebase:\n";
      console.error(errMsg, err);
      // setErrorMessage(errMsg);  // TODO
    } finally {
      setIsLoading(false);
    }
  };

  const firebaseUserSignOut = async () => {
    setIsLoading(true);
    const auth = getAuth();

    try {
      await signOut(auth);
      console.log("User signed out");
      // setMessage(msg);  // TODO

      // Update the user context and sign in status
      localStorage.setItem("meritoSignedIn", JSON.stringify(false));
      setCurrentMeritoUser(null);
      setMeritoSignedIn(false);
    } catch (err) {
      const errMsg = "Failed to sign out:\n";
      console.error(errMsg, err);
      // setErrorMessage(errMsg);  // TODO
    } finally {
      setIsLoading(false);
    }
  };

  const firebaseBuildAuthHeader = async () => {
    const auth = getAuth();

    try {
      const firebaseUser = auth.currentUser;
      if (firebaseUser) {
        const idToken = await getIdToken(firebaseUser, true);
        console.log("Firebase ID Token retrieved");
        // setMessage(msg);  // TODO

        // If user is signed in, return an authorization header with ID token
        const authHeader = {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        return authHeader;
      } else {
        // If user is not signed in, omit the authorization header entirely
        return {};
      }
    } catch (err) {
      const errMsg = "Failed to retrieve Firebase ID token:\n";
      console.error(errMsg, err);
      // setErrorMessage(errMsg);  // TODO
    }
  };

  const meritoUserSignUp = async (firebaseUser, signUpFormData) => {
    setIsLoading(true);

    try {
      if (firebaseUser) {
        // Send ID token and user data to create a new user in Merito DB
        console.log(`Calling Merito API: ${ENDPOINT_SIGN_UP}`);
        const authHeader = await firebaseBuildAuthHeader();
        const response = await axios.post(
          `${MERITO_API_HOST}${ENDPOINT_SIGN_UP}`,
          signUpFormData,
          {
            headers: {
              ...authHeader.headers,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const meritoUser = response.data.content;
        console.log("Merito user signed up");
        // setMessage(msg);  // TODO

        firebaseUserSignOut();
        return meritoUser;
      } else {
        const errMsg = "Firebase user not found";
        console.error(errMsg);
        // setErrorMessage(errMsg);  // TODO
        return null;
      }
    } catch (err) {
      const errMsg = "Failed to sign up Merito user:\n";
      console.error(errMsg, err);
      // setErrorMessage(errMsg);  // TODO
    } finally {
      setIsProcessing(false);
      setIsLoading(false);
    }
  };

  const meritoUserSignIn = async (firebaseUser) => {
    setIsLoading(true);

    try {
      if (firebaseUser) {
        // Send ID token and verify it
        console.log(`Calling Merito API: ${ENDPOINT_SIGN_IN}`);
        const authHeader = await firebaseBuildAuthHeader();
        const response = await axios.post(
          `${MERITO_API_HOST}${ENDPOINT_SIGN_IN}`,
          null, // No request body
          authHeader
        );

        // Update the user context and sign in status
        const meritoUser = response.data.content;
        localStorage.setItem("meritoSignedIn", JSON.stringify(true));
        setCurrentMeritoUser(meritoUser);
        setMeritoSignedIn(true);

        console.log("User signed in:\n", meritoUser);
        // setMessage(msg);  // TODO

        return meritoUser;
      } else {
        // Update the user context and sign in status
        localStorage.setItem("meritoSignedIn", JSON.stringify(false));
        setCurrentMeritoUser(null);
        setMeritoSignedIn(false);

        const errMsg = "Firebase user not found";
        console.error(errMsg);
        // setErrorMessage(errMsg);  // TODO
        return null;
      }
    } catch (err) {
      const errMsg = "Failed to sign in to Merito";
      console.error(errMsg, err);
      alert(
        "Failed to sign in to Merito. Please try again or sign up for an account."
      );
      // setErrorMessage(errMsg);  // TODO
    } finally {
      setIsProcessing(false);
      setIsLoading(false);
    }
  };

  // // Update the user context and sign in status when location changes
  // useEffect(() => {
  //   // // Skip the proper user auth check on the paths that don't require it
  //   // if (!requiresUserAuthCheck(location.pathname)) {
  //   //   return;
  //   // }

  //   const auth = getAuth();

  //   const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
  //     setIsLoading(true);

  //     if (firebaseUser) {
  //       // User is signed in in Firebase
  //       if (isProcessing) {
  //         // Skip the process if the user is currently signing up
  //         setIsLoading(false);
  //         return;
  //       }

  //       if (
  //         JSON.parse(localStorage.getItem("meritoSignedIn")) === false ||
  //         currentMeritoUser === null ||
  //         meritoSignedIn === false
  //       ) {
  //         // Sign in the user in Merito and update the user context and sign in status
  //         const _ = await meritoUserSignIn(firebaseUser);
  //       }
  //     } else {
  //       // User is signed out in Firebase
  //       // Update the user context and sign in status
  //       localStorage.setItem("meritoSignedIn", JSON.stringify(false));
  //       setCurrentMeritoUser(null);
  //       setMeritoSignedIn(false);
  //     }

  //     console.log(
  //       `Sign In Status: ${JSON.parse(localStorage.getItem("meritoSignedIn"))}`
  //     );
  //     setIsLoading(false);
  //   });

  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, [location]);

  return (
    <UserAuthContext.Provider
      value={{
        // States
        currentMeritoUser,
        setCurrentMeritoUser,
        meritoSignedIn,
        setMeritoSignedIn,
        isProcessing,
        setIsProcessing,
        // Functions
        firebaseUserSignUp,
        firebaseUserSignIn,
        firebaseUserSignOut,
        firebaseBuildAuthHeader,
        meritoUserSignUp,
        meritoUserSignIn,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => useContext(UserAuthContext);
