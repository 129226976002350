import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  // Custom color themes
  colors: {
    ScienceDeals: {
      // Forest Green
      50: "#F4F9F7", // Lightest
      100: "#DBECE5",
      200: "#B8D7CC",
      300: "#8CBCAC",
      400: "#649D8D",
      500: "#4A8273", // Mid
      600: "#39685C",
      700: "#31544B",
      800: "#2A453F",
      900: "#263B36",
      950: "#12211F", // Darkest
    },
    PatentDeals: {
      // Dusty Rose
      50: "#F9F6F8", // Lightest
      100: "#F5EEF1",
      200: "#ECDEE5",
      300: "#DEC3CF",
      400: "#C89EB0",
      500: "#B47F94", // Mid
      600: "#9E6478",
      700: "#865061",
      800: "#704452",
      900: "#5F3C47",
      950: "#372027", // Darkest
    },
    Primary: {
      // Bright Blue
      50: "#EDFAFF",
      100: "#D6F2FF",
      200: "#B5EAFF",
      300: "#83DFFF",
      400: "#48CBFF",
      500: "#1EADFF",
      600: "#068FFF",
      700: "#007BFF", // Main
      800: "#085EC5",
      900: "#0D519B",
      950: "#0E315D",
    },
    Secondary: {
      // Bright Orange
      50: "#FFF4ED",
      100: "#FFE5D4",
      200: "#FFC7A8",
      300: "#FFA071",
      400: "#FF7F50", // Main
      500: "#FE4711",
      600: "#EF2D07",
      700: "#C61C08",
      800: "#9D190F",
      900: "#7E1810",
      950: "#440806",
    },
    Highlight: {
      // Bright Yellow
      50: "#FFFFE7",
      100: "#FEFFC1",
      200: "#FFFD86",
      300: "#FFF441",
      400: "#FFE60D",
      500: "#FFD700", // Main
      600: "#D19E00",
      700: "#A67102",
      800: "#89580A",
      900: "#74480F",
      950: "#442604",
    },
    Neutral: {
      // Light Grey
      0: "#FFFFFF", // White
      50: "#F5F5F5",
      100: "#EFEFEF",
      200: "#DCDCDC",
      300: "#BDBDBD",
      400: "#989898",
      500: "#7C7C7C", // Mid
      600: "#656565",
      700: "#525252",
      800: "#464646",
      900: "#3D3D3D",
      950: "#292929",
      1000: "#000000", // Black
    },
  },
  components: {
    // Custom color schemes for the Button component
    //
    // When applying them in Button, use "variant" ("colorScheme" does not work)
    // e.g., <Button variant="ScienceDeals">Submit</Button>
    //
    // Any value in pseudo-style attributes like _hover, _disabled, etc needs to be defined in each variant
    // e.g., _hover: { boxShadow: "xl" }, _disabled: { cursor: "not-allowed" }
    Button: {
      // Styles for different visual variants ("outline", "solid", etc)
      variants: {
        // Forest Green
        ScienceDeals: {
          bg: "ScienceDeals.500",
          color: "Neutral.50",
          _hover: {
            bg: "ScienceDeals.600",
            color: "Neutral.100",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "ScienceDeals.300",
            color: "Neutral.50",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            cursor: "not-allowed",
          },
        },

        // Dusty Rose
        PatentDeals: {
          bg: "PatentDeals.500",
          color: "Neutral.50",
          _hover: {
            bg: "PatentDeals.600",
            color: "Neutral.100",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "PatentDeals.300",
            color: "Neutral.50",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            cursor: "not-allowed",
          },
        },

        //
        BluePrimary: {
          bg: "Primary.400",
          color: "Neutral.50",
          _hover: {
            bg: "Primary.500",
            color: "Neutral.100",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "Primary.300",
            color: "Neutral.50",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            // Any value in pseudo-style attributes like _hover, _disabled, etc
            cursor: "not-allowed",
          },
        },
        //
        BlueSecondary: {
          bg: "Primary.100",
          color: "Primary.400",
          _hover: {
            bg: "Primary.200",
            color: "Primary.500",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "Neutral.200",
            color: "Neutral.500",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            cursor: "not-allowed",
          },
        },

        // sign-in, sign-up buttons
        OrangePrimary: {
          bg: "Secondary.400",
          color: "Neutral.50",
          _hover: {
            bg: "Secondary.500",
            color: "Neutral.100",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "Secondary.300",
            color: "Neutral.50",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            // Any value in pseudo-style attributes like _hover, _disabled, etc
            cursor: "not-allowed",
          },
        },
        // Back + Next buttons
        OrangeSecondary: {
          bg: "Secondary.100",
          color: "Secondary.400",
          _hover: {
            bg: "Secondary.200",
            color: "Secondary.500",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "Neutral.200",
            color: "Neutral.500",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            cursor: "not-allowed",
          },
        },

        //
        YellowPrimary: {
          bg: "Highlight.400",
          color: "Neutral.50",
          _hover: {
            bg: "Highlight.500",
            color: "Neutral.100",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "Highlight.300",
            color: "Neutral.50",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            // Any value in pseudo-style attributes like _hover, _disabled, etc
            cursor: "not-allowed",
          },
        },
        //
        YellowSecondary: {
          bg: "Highlight.100",
          color: "Highlight.400",
          _hover: {
            bg: "Highlight.200",
            color: "Highlight.500",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "Neutral.200",
            color: "Neutral.500",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            cursor: "not-allowed",
          },
        },

        // Browse Projects button
        NeutralPrimary: {
          bg: "Neutral.100",
          color: "Neutral.950",
          _hover: {
            bg: "Neutral.700",
            color: "Neutral.200",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "Neutral.300",
            color: "Neutral.500",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            cursor: "not-allowed",
          },
        },
        // Contact Us button
        NeutralSecondary: {
          bg: "Neutral.800",
          color: "Neutral.50",
          _hover: {
            bg: "Neutral.200",
            color: "Neutral.800",
            boxShadow: "xl",
          },
          _disabled: {
            bg: "Neutral.300",
            color: "Neutral.500",
            // Explicitly disable _hover effects for isDisabled={true} state
            // Otherwise it uses color in _hover
            _hover: {
              bg: "Neutral.600",
              color: "Neutral.600",
            },
            cursor: "not-allowed",
          },
        },
      },

      // Styles for different sizes ("sm", "md", "lg")
      sizes: {
        sm: { fontSize: "sm" },
        md: { fontSize: "md" },
        lg: { fontSize: "lg" },
      },

      // Style object for base or default style
      baseStyle: {
        fontSize: "lg",
        fontWeight: 500,
        borderRadius: "lg",
        boxShadow: "md",
      },

      // default values for "size", "variant" and "colorScheme"
      defaultProps: {
        size: "lg",
      },
    },
  },
});

export default theme;
