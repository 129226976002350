import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Initial language
    lng: "en",
    // Fallback to English in case a translation is missing
    fallbackLng: "en",
    // Logs info level to console output if true
    debug: false,
    // React already safes from xss
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    // List of namespaces
    ns: [
      "header",
      "footer",
      "home",
      "creators",
      "buyers",
      "about_us",
      "contact",
      "portal",
    ],
    // Default namespace
    defaultNS: "home",
  });

export default i18n;
