import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Link,
  Textarea,
  Button,
  Text,
  VStack,
  HStack,
  useToast,
  FormHelperText,
  InputGroup,
  InputLeftAddon,
  Select,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { useLoading } from "../context/LoadingProvider";
import { useUserAuth } from "../context/UserAuthProvider";
import {
  formatNumber,
  parseNumber,
  getCurrentJSTDateTime,
} from "../utilities/utilities";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT = "/science_deals/project";

export default function ScienceDealsProjectCreation() {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { currentMeritoUser, meritoSignedIn, firebaseBuildAuthHeader } =
    useUserAuth();

  const [currentStep, setCurrentStep] = useState(1);
  const [projectForm, setProjectForm] = useState({
    project_name: null,
    project_owner: null,
    organization: null,
    jurisdiction: null,
    project_description: null,
    project_goal: null,
    project_start_date: null,
    project_duration: null,
    suggested_price_currency: null,
    suggested_price: null,
    project_image: null,
    project_document: null,
    disclaimer_checked_at: null,
  });
  const [disclaimerChecked, setDisclaimerChecked] = useState(false);
  const [suggestedPriceFormatted, setSuggestedPriceFormatted] = useState("");
  // const [message, setMessage] = useState(null);  // TODO
  // const [errorMessage, setErrorMessage] = useState(null);  // TODO

  const handleNext = () => {
    const nextStep = currentStep === 5 ? currentStep : currentStep + 1;
    setCurrentStep(nextStep);
  };

  const handleBack = () => {
    const prevStep = currentStep === 1 ? currentStep : currentStep - 1;
    setCurrentStep(prevStep);
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value, type, files } = event.target;

    if (name === "suggestedPriceFormatted") {
      // Update both formatted and raw values
      setSuggestedPriceFormatted(formatNumber(value));
      setProjectForm({ ...projectForm, suggested_price: parseNumber(value) });
    } else if (type === "file") {
      setProjectForm({ ...projectForm, [name]: files[0] });
    } else {
      setProjectForm({ ...projectForm, [name]: value });
    }
  };

  const handleDisclaimerCheck = () => {
    const previousCheckStatus = disclaimerChecked;

    let currentJSTDateTime = null;
    if (!previousCheckStatus) {
      currentJSTDateTime = getCurrentJSTDateTime();
    }

    setProjectForm({
      ...projectForm,
      disclaimer_checked_at: currentJSTDateTime,
    });
    setDisclaimerChecked(!previousCheckStatus);
  };

  const checkSignInStatus = () => {
    // Move to the sign-in page if the user is not signed in
    if (!JSON.parse(localStorage.getItem("meritoSignedIn"))) {
      navigate("/sign-in");
    }
  };

  const handleProjectCreation = async (event) => {
    event.preventDefault();
    checkSignInStatus();
    setIsLoading(true);

    try {
      // Use FormData for File Uploads
      const projectFormData = new FormData();
      projectFormData.append("project_name", projectForm.project_name);
      projectFormData.append("project_owner", projectForm.project_owner);
      projectFormData.append("organization", projectForm.organization);
      projectFormData.append("jurisdiction", projectForm.jurisdiction);
      projectFormData.append(
        "project_description",
        projectForm.project_description
      );
      projectFormData.append("project_goal", projectForm.project_goal);
      projectFormData.append(
        "project_start_date",
        projectForm.project_start_date
      );
      projectFormData.append("project_duration", projectForm.project_duration);
      projectFormData.append(
        "suggested_price_currency",
        projectForm.suggested_price_currency
      );
      projectFormData.append("suggested_price", projectForm.suggested_price);
      projectFormData.append("project_image", projectForm.project_image);
      projectFormData.append("project_document", projectForm.project_document);
      projectFormData.append(
        "disclaimer_checked_at",
        projectForm.disclaimer_checked_at
      );
      projectFormData.append("user_id_seller", currentMeritoUser.user_id);
      projectFormData.append("user_id_buyer", null);

      console.log(`Calling Merito API: ${ENDPOINT}`);
      const authHeader = await firebaseBuildAuthHeader();
      const response = await axios.post(
        `${MERITO_API_HOST}${ENDPOINT}`,
        projectFormData,
        {
          headers: {
            ...authHeader.headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const project = response.data.content;

      console.log("Project created:\n", project);
      // setMessage(msg);  // TODO

      // TODO: Turn on SRA generation process
      // navigate("/science-deals/sra-generation", { state: { project } });
      navigate("/science-deals/listing");
    } catch (err) {
      const errMsg = "Failed to create Science Deals project:\n";
      console.error(errMsg, err);
      // setErrorMessage(errMsg);  // TODO
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkSignInStatus();
  }, [meritoSignedIn]);

  return (
    <Flex
      w="full"
      align="top"
      justifyContent="center"
      minH="100vh"
      p={6}
      pt={14}
    >
      <Box
        w="full"
        maxW="1200px"
        h="83vh"
        borderRadius="xl"
        boxShadow="4xl"
        overflow="hidden"
      >
        <Flex direction={{ base: "column", md: "row" }} h="83vh">
          {/* Form Left Side */}
          <Box
            w={{ base: "100%", md: "50%" }}
            bg="ScienceDeals.800"
            color="Neutral.50"
            fontWeight={300}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            position="relative"
            p={10}
            zIndex={0}
          >
            <Text fontSize="4xl" zIndex={1}>
              Science Deals
            </Text>
            <Text fontSize="md" mt={2} zIndex={1}>
              Make science funding more{" "}
              <Text as="span" textDecoration="underline">
                merito
              </Text>
              cratic.
            </Text>
            <Box
              position="absolute"
              top="7%"
              left="5%"
              w="110px"
              h="110px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
            <Box
              position="absolute"
              top="6%"
              right="-17%"
              w="310px"
              h="310px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
            <Box
              position="absolute"
              bottom="25%"
              left="16%"
              w="75px"
              h="75px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
            <Box
              position="absolute"
              bottom="14%"
              right="37%"
              w="220px"
              h="220px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
            <Box
              position="absolute"
              bottom="3%"
              right="37%"
              w="120px"
              h="120px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
          </Box>

          {/* Form Right Side */}
          <Box
            w={{ base: "100%", md: "50%" }}
            h="83vh"
            display="flex"
            flexDirection="column"
            justifyContent="top"
            alignItems="left"
            textAlign="left"
            position="relative"
            overflow="hidden"
            bg="Neutral.0"
            color="ScienceDeals.600"
            p={10}
            zIndex={1}
          >
            {/* Project Creation Form */}
            <VStack spacing={4} as="form">
              <Text color="ScienceDeals.500" fontSize={"5xl"} fontWeight={500}>
                Create Project
              </Text>

              <FormControl
                id="project_name"
                isRequired
                hidden={currentStep !== 1}
              >
                <FormLabel>Project Name</FormLabel>
                <Input
                  type="text"
                  name="project_name"
                  value={projectForm.project_name}
                  onChange={handleInputChange}
                  placeholder="Title of the project"
                />
              </FormControl>

              <FormControl
                id="project_owner"
                isRequired
                hidden={currentStep !== 1}
              >
                <FormLabel>Project Owner</FormLabel>
                <Input
                  type="text"
                  name="project_owner"
                  value={projectForm.project_owner}
                  onChange={handleInputChange}
                  placeholder="Name of the entity who owns the project"
                />
              </FormControl>

              <FormControl
                id="organization"
                isRequired
                hidden={currentStep !== 1}
              >
                <FormLabel>Organization</FormLabel>
                <Input
                  type="text"
                  name="organization"
                  value={projectForm.organization}
                  onChange={handleInputChange}
                  placeholder="Name of their affiliated organization/university"
                />
              </FormControl>

              <FormControl
                id="jurisdiction"
                isRequired
                hidden={currentStep !== 1}
              >
                <FormLabel>Jurisdiction</FormLabel>
                <Select
                  name="jurisdiction"
                  value={projectForm.jurisdiction}
                  onChange={handleInputChange}
                  defaultValue="Select option"
                  focusBorderColor="brand.400"
                >
                  <option value="Select option" disabled>
                    Select option
                  </option>
                  <option value="United States">United States</option>
                  <option value="Japan">Japan</option>
                </Select>
              </FormControl>

              <FormControl
                id="project_description"
                isRequired
                hidden={currentStep !== 2}
              >
                <FormLabel>Project Description</FormLabel>
                <Textarea
                  name="project_description"
                  value={projectForm.project_description}
                  onChange={handleInputChange}
                  placeholder="Description of the project"
                  size="md"
                />
              </FormControl>

              <FormControl
                id="project_goal"
                isRequired
                hidden={currentStep !== 2}
              >
                <FormLabel>Project Goal</FormLabel>
                <Textarea
                  name="project_goal"
                  value={projectForm.project_goal}
                  onChange={handleInputChange}
                  placeholder="Goal of the project"
                  size="md"
                />
              </FormControl>

              <FormControl
                id="project_start_date"
                isRequired
                hidden={currentStep !== 3}
              >
                <FormLabel>Project Start Date</FormLabel>
                <Input
                  type="date"
                  name="project_start_date"
                  value={projectForm.project_start_date}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl
                id="project_duration"
                isRequired
                hidden={currentStep !== 3}
              >
                <FormLabel>Project Duration</FormLabel>
                <Select
                  name="project_duration"
                  value={projectForm.project_duration}
                  onChange={handleInputChange}
                  defaultValue="Select option"
                  focusBorderColor="brand.400"
                >
                  <option value="Select option" disabled>
                    Select option
                  </option>
                  <option value="1">1. &nbsp; ~ 6 months</option>
                  <option value="2">2. &nbsp; 6 months ~ 1 year</option>
                  <option value="3">3. &nbsp; 1 year ~ 3 years</option>
                  <option value="4">4. &nbsp; 3 years ~ 5 years</option>
                  <option value="5">5. &nbsp; 5 years ~ 10 years</option>
                  <option value="6">6. &nbsp; 10 years ~</option>
                </Select>
              </FormControl>

              <FormControl
                id="suggested_price"
                isRequired
                hidden={currentStep !== 3}
              >
                <FormLabel>Suggested Price</FormLabel>
                <FormHelperText>Currency</FormHelperText>
                <Select
                  name="suggested_price_currency"
                  value={projectForm.suggested_price_currency}
                  onChange={handleInputChange}
                  defaultValue="Select option"
                  focusBorderColor="brand.400"
                  mt={2}
                >
                  <option value="Select option" disabled>
                    Select option
                  </option>
                  <option value="JPY">JPY</option>
                  <option value="USD">USD</option>
                </Select>

                <FormHelperText>Price</FormHelperText>
                <Input
                  type="text"
                  name="suggestedPriceFormatted"
                  value={suggestedPriceFormatted}
                  onChange={(e) => setSuggestedPriceFormatted(e.target.value)}
                  onBlur={handleInputChange}
                  placeholder="Ask price of the project"
                  mt={2}
                />
              </FormControl>

              <FormControl
                id="project_image"
                isRequired
                hidden={currentStep !== 4}
              >
                <FormLabel>Project Image</FormLabel>
                <FormHelperText>
                  Image that represents the project
                </FormHelperText>
                <Input
                  type="file"
                  name="project_image"
                  onChange={handleInputChange}
                  mt={2}
                  p={1}
                />
              </FormControl>

              <FormControl
                id="project_document"
                isRequired
                hidden={currentStep !== 4}
              >
                <FormLabel>Project Document</FormLabel>
                <FormHelperText>PDF document about the project</FormHelperText>
                <Input
                  type="file"
                  name="project_document"
                  onChange={handleInputChange}
                  mt={2}
                  p={1}
                />
              </FormControl>

              <Checkbox
                id="disclaimerChecked"
                name="disclaimerChecked"
                value={disclaimerChecked}
                onChange={handleDisclaimerCheck}
                mt={10}
                hidden={currentStep !== 5}
                size={"lg"}
              >
                <Text fontWeight={400} fontSize="lg" ml={4}>
                  Agree to the{" "}
                  <Link
                    color="Secondary.300"
                    href="/terms-and-conditions"
                    isExternal
                  >
                    terms and conditions
                  </Link>{" "}
                  and{" "}
                  <Link color="Secondary.300" href="/privacy-policy" isExternal>
                    privacy policy
                  </Link>{" "}
                  and confirm the{" "}
                  <Link
                    color="Secondary.300"
                    onClick={() => alert("Disclaimer clicked")}
                  >
                    disclaimer
                  </Link>{" "}
                  .
                </Text>
              </Checkbox>

              {/* Submit */}
              <Button
                isDisabled={!disclaimerChecked}
                hidden={currentStep !== 5}
                variant="ScienceDeals"
                mt={12}
                onClick={handleProjectCreation}
              >
                Create
              </Button>
            </VStack>

            {/* Back / Next Buttons */}
            <Button
              isDisabled={currentStep === 1}
              w="100px"
              variant="OrangeSecondary"
              position="absolute"
              bottom="10%"
              left="12%"
              onClick={handleBack}
            >
              Back
            </Button>
            <Text
              as="span"
              color="Neutral.400"
              fontSize="md"
              fontWeight={500}
              position="absolute"
              bottom="11%"
              right="47%"
            >
              {currentStep} / 5
            </Text>
            <Button
              isDisabled={currentStep === 5}
              w="100px"
              variant="OrangeSecondary"
              position="absolute"
              bottom="10%"
              right="12%"
              onClick={handleNext}
            >
              Next
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
