import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider, CSSReset, Flex, VStack } from "@chakra-ui/react";

// General Settings
import theme from "./theme";
import "./i18n";
import { LoadingProvider } from "./context/LoadingProvider";
import { UserAuthProvider } from "./context/UserAuthProvider";

// Header, Footer
import Header from "./components/Header";
import Footer from "./components/Footer";

// Pages
// General - Static
import Home from "./components/Home";
import HomeContactForm from "./components/HomeContactForm";
import Creators from "./components/Creators";
import Buyers from "./components/Buyers";
import AboutUs from "./components/AboutUs";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Placeholder from "./components/Placeholder";

// General - Dynamic
import Portal from "./components/Portal";
import Deal from "./components/Deal";

// Science Deals
import ScienceDealsDashboard from "./components/ScienceDealsDashboard";
import ScienceDealsProjectCreation from "./components/ScienceDealsProjectCreation";
import ScienceDealsSRAGeneration from "./components/ScienceDealsSRAGeneration";
import ScienceDealsListing from "./components/ScienceDealsListing";
import ScienceDealsProject from "./components/ScienceDealsProject";

// Patent Deals
import PatentDealsDashboard from "./components/PatentDealsDashboard";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Router>
        <LoadingProvider>
          <UserAuthProvider>
            <Flex
              className="en"
              bg="Neutral.950"
              flexDirection="column"
              minH="100vh"
            >
              <Header />
              <VStack as="main" flex="1">
                <Routes>
                  {/* Header */}
                  <Route path="/creators" element={<Creators />} />
                  <Route path="/buyers" element={<Buyers />} />
                  {/* <Route path="/sign-in" element={<SignIn />} />
                  <Route path="/sign-up" element={<SignUp />} />
                  <Route path="/portal" element={<Portal />} /> */}

                  {/* Footer */}
                  <Route path="/about-us" element={<AboutUs />} />

                  {/* General - Static */}
                  <Route path="/" element={<Home />} />
                  <Route path="/contact" element={<HomeContactForm />} />
                  {/* <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}

                  {/* General - Dynamic */}
                  {/* <Route path="/user/:user_id" element={<Placeholder />} />
                  <Route path="/deal/:deal_id" element={<Placeholder />} /> */}

                  {/* Science Deals */}
                  {/* <Route
                    path="/science-deals/dashboard"
                    element={<ScienceDealsDashboard />}
                  />
                  <Route
                    path="/science-deals/project-creation"
                    element={<ScienceDealsProjectCreation />}
                  />
                  <Route
                    path="/science-deals/sra-generation"
                    element={<ScienceDealsSRAGeneration />}
                  />
                  <Route
                    path="/science-deals/listing"
                    element={<ScienceDealsListing />}
                  />
                  <Route
                    path="/science-deals/project/:project_id"
                    element={<ScienceDealsProject />}
                  /> */}

                  {/* Patent Deals */}
                  {/* <Route
                    path="/patent-deals/dashboard"
                    element={<Placeholder />}
                    // Use actual component when ready
                    // element={<PatentDealsDashboard />}
                  />
                  <Route
                    path="/patent-deals/patent-creation"
                    element={<Placeholder />}
                  />
                  <Route
                    path="/patent-deals/listing"
                    element={<Placeholder />}
                  />
                  <Route
                    path="/patent-deals/patent/:patent_id"
                    element={<Placeholder />}
                  /> */}
                </Routes>
              </VStack>
              <Footer />
            </Flex>
          </UserAuthProvider>
        </LoadingProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
