import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Select,
  FormHelperText,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment-timezone";

import { useLoading } from "../context/LoadingProvider";
import { useUserAuth } from "../context/UserAuthProvider";
import { formatNumber, parseNumber } from "../utilities/utilities";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];
const ENDPOINT = "/science_deals/sra";

export default function ScienceDealsSRAGeneration() {
  const navigate = useNavigate();
  const { project } = useLocation().state || {};
  const { setIsLoading } = useLoading();
  const { currentMeritoUser, meritoSignedIn, firebaseBuildAuthHeader } =
    useUserAuth();

  const [SRAForm, setSRAForm] = useState({
    // TODO: Update fields
    project_name: project?.project_name,
    project_owner: project?.project_owner,
    organization_seller: project?.organization,
    asset_type: null,
    amount_unit: null,
    amount: null,
    project_id: project?.project_id,
  });
  const [amountFormatted, setAmountFormatted] = useState("");
  // const [message, setMessage] = useState(null);  // TODO
  // const [errorMessage, setErrorMessage] = use State(null);  // TODO

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === "amountFormatted") {
      // Update both formatted and raw values
      setAmountFormatted(formatNumber(value));
      setSRAForm({ ...SRAForm, amount: parseNumber(value) });
    } else {
      setSRAForm({ ...SRAForm, [name]: value });
    }
  };

  const checkSignInStatus = () => {
    // Move to the sign-in page if the user is not signed in
    if (!JSON.parse(localStorage.getItem("meritoSignedIn"))) {
      navigate("/sign-in");
    }
  };

  const handleSRAGeneration = async (event) => {
    event.preventDefault();
    checkSignInStatus();
    setIsLoading(true);

    try {
      const sraFormData = {
        ...SRAForm,
        organization_buyer: null,
        user_id_seller: currentMeritoUser.user_id,
        user_id_buyer: null,
      };

      console.log(`Calling Merito API: ${ENDPOINT}`);
      const authHeader = await firebaseBuildAuthHeader();
      const response = await axios.post(
        `${MERITO_API_HOST}${ENDPOINT}`,
        sraFormData,
        authHeader
      );
      const sra = response.data.content;

      console.log("SRA document generated:\n", sra);
      // setMessage(msg);  // TODO
      navigate("/science-deals/listing");
    } catch (err) {
      const errMsg = "Failed to generate SRA document:\n";
      console.error(errMsg, err);
      // setErrorMessage(errMsg);  // TODO
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Update project data when project from useLocation changes
    if (Object.keys(project).length > 0) {
      setSRAForm({
        ...SRAForm,
        project_name: project.project_name,
        project_owner: project.project_owner,
        organization_seller: project.organization,
        project_id: project.project_id,
      });
    }
  }, [project]);

  useEffect(() => {
    checkSignInStatus();
  }, [meritoSignedIn]);

  return (
    <Flex
      w="full"
      h={"full"}
      align="top"
      justifyContent="center"
      minH="80vh"
      py={20}
    >
      <Box
        p={10}
        maxW="700px"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
        bg="Neutral.0"
        color="ScienceDeals.600"
      >
        <VStack spacing={4} as="form" align="stretch">
          <Text color="ScienceDeals.500" fontSize={"5xl"} fontWeight={500}>
            SRA (Sponsored Research Agreement) Form
          </Text>
          <Text fontSize="lg" mb={2}>
            SRA document will be generated after you fill out the details and
            press Generate SRA.
          </Text>

          <FormControl id="project_name" isRequired isReadOnly>
            <FormLabel>Project Name</FormLabel>
            <Input
              type="text"
              name="project_name"
              value={SRAForm.project_name}
              onChange={handleInputChange}
              placeholder="Name of the project"
            />
          </FormControl>

          <FormControl id="project_owner" isRequired>
            <FormLabel>Project Owner</FormLabel>
            <Input
              type="text"
              name="project_owner"
              value={SRAForm.project_owner}
              onChange={handleInputChange}
              placeholder="Name of the project owner"
            />
          </FormControl>

          <FormControl id="organization_seller" isRequired>
            <FormLabel>Organization</FormLabel>
            <Input
              type="text"
              name="organization_seller"
              value={SRAForm.organization_seller}
              onChange={handleInputChange}
              placeholder="Name of the organization"
            />
          </FormControl>

          <FormControl id="asset_type" isRequired>
            <FormLabel>Asset Type</FormLabel>
            <Input
              type="text"
              name="asset_type"
              value={SRAForm.asset_type}
              onChange={handleInputChange}
              placeholder="Equity, Debt"
            />
          </FormControl>

          <FormControl id="amount" isRequired>
            <FormLabel>Amount</FormLabel>
            <FormHelperText>Unit</FormHelperText>
            <Select
              name="amount_unit"
              value={SRAForm.amount_unit}
              onChange={handleInputChange}
              defaultValue="Select option"
              focusBorderColor="brand.400"
              mt={2}
            >
              <option value="Select option" disabled>
                Select option
              </option>
              <option value="%">%</option>
              <option value="$">$</option>
              <option value="¥">¥</option>
            </Select>
            <FormHelperText>Amount</FormHelperText>
            <Input
              type="text"
              name="amountFormatted"
              value={amountFormatted}
              onChange={(e) => setAmountFormatted(e.target.value)}
              onBlur={handleInputChange}
              placeholder="Amount"
              mt={2}
            />
          </FormControl>

          <Button
            w="40"
            variant="ScienceDeals"
            mt={2}
            onClick={handleSRAGeneration}
          >
            Generate SRA
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
}
