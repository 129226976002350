import {
  Box,
  Flex,
  Container,
  Text,
  Button,
  Stack,
  Image,
  Icon,
  Link,
  useColorModeValue,
  createIcon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LuGalleryVerticalEnd } from "react-icons/lu";
import { RiQuestionLine } from "react-icons/ri";

export default function Home() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("home");

  return (
    <Box>
      <Container maxW={{ base: "3xl", sm: "3xl", md: "3xl", lg: "4xl" }}>
        <Stack
          as={Box}
          textAlign="left"
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 32 }}
        >
          <Text
            textAlign="left"
            color="Neutral.0"
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl", lg: "6xl" }}
            fontWeight={600}
            lineHeight="110%"
            px={9}
          >
            {t("title")}
          </Text>
          <Box
            className={i18n.language === "jp" ? "jp" : "en"}
            bgGradient="linear(to-r, ScienceDeals.800, PatentDeals.800)"
            color="Neutral.0"
            fontSize={{ base: "lg", sm: "lg", md: "xl", lg: "xl" }}
            fontWeight="500"
            borderRadius="2xl"
            boxShadow="2xl"
            px={{ base: 12, md: 20 }}
            py={{ base: 10, md: 16 }}
          >
            <Text
              as="span"
              fontSize={{ base: "xl", sm: "xl", md: "2xl", lg: "2xl" }}
            >
              Merito
            </Text>
            {t("description_1")}
            <Text as="span" color={"Secondary.200"}>
              {t("description_2", "")}
            </Text>
            {t("description_3", "")}
            <Text as="span" color={"Secondary.200"}>
              {t("description_4", "")}
            </Text>
            {t("description_5", "")}
            <br />
            {t("description_6", "")}

            <br />
            <br />
            <Text as="span" color={"Secondary.200"}>
              {t("description_7", "")}
            </Text>

            <br />
            <br />
            <Text as="span">
              {t("description_8", "")}
              <Link
                color="Secondary.300"
                onClick={() => {
                  navigate("/creators");
                }}
              >
                {t("creators", "")}
              </Link>
              {t("and", "")}
              <Link
                color="Secondary.300"
                onClick={() => {
                  navigate("/buyers");
                }}
              >
                {t("buyers", "")}
              </Link>
              {t("description_9", "")}
            </Text>
          </Box>
          <Stack
            className={i18n.language === "jp" ? "jp" : "en"}
            direction={{ base: "column", md: "row" }}
            justify="center"
            align="center"
            spacing={{ base: 6, md: 16 }}
            position="relative"
            mt={{ base: 5, md: 0 }}
          >
            {/* <Button
              w={230}
              variant="NeutralSecondary"
              borderRadius="3xl"
              boxShadow="2xl"
              onClick={() => navigate("/science-deals/listing")}
            >
              <Icon w={5} h={5} as={LuGalleryVerticalEnd} mr={3} />
              {t("browse_projects_button")}
            </Button> */}
            <Button
              w={230}
              variant="NeutralSecondary"
              borderRadius="3xl"
              boxShadow="2xl"
              onClick={() => navigate("/contact")}
            >
              <Icon w={5} h={5} as={RiQuestionLine} mr={3} />
              {t("contact_button")}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
